var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-page"},[_c('div',{staticClass:"aboutdpp-header",style:(`background-image: url(${_vm.group3Img})`)},[_vm._m(0)]),_c('div',{staticClass:"aboutdpp-what"},[_c('div',{staticClass:"aboutdpp-title"},[_c('img',{attrs:{"src":_vm.decorationIcon}}),_c('label',[_vm._v("什么是产品数字护照?")])]),_c('div',{staticClass:"aboutdpp-content"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"aboutdpp-content_img"},[_c('img',{attrs:{"src":_vm.group1Img}})]),_vm._m(3),_vm._m(4),_c('div',{staticClass:"aboutdpp-content_img"},[_c('img',{attrs:{"src":_vm.group2Img}})])])]),_c('div',{staticClass:"aboutdpp-action",style:(`background-image: url(${_vm.frameImg})`)},[_c('div',{staticClass:"aboutdpp-title"},[_c('img',{attrs:{"src":_vm.decorationIcon}}),_c('label',[_vm._v("产品数字护照的实施流程")])]),_c('div',{staticClass:"aboutdpp-content"})]),_c('div',{staticClass:"aboutdpp-meaning"},[_vm._m(5),_c('div',{staticClass:"aboutdpp-list"},_vm._l((_vm.collapseList),function(item,index){return _c('div',{key:index,staticClass:"aboutdpp-list_item"},[_c('div',{staticClass:"list_item-header",on:{"click":function($event){return _vm.collapse(index, item, 'collapseList')}}},[_c('label',[_vm._v(_vm._s(index + 1)+"、")]),_c('span',[_vm._v(_vm._s(item.title))]),_c('img',{class:['arrow-icon', item.collapsed ? 'img-collapseUp' : 'img-collapseDown'],attrs:{"src":_vm.arrowIcon}})]),(item.collapsed)?_c('div',{staticClass:"list_item-content"},_vm._l((item.list),function(subItem,subIndex){return _c('p',{key:subIndex},[_c('label',[_vm._v(_vm._s(subItem))])])}),0):_vm._e()])}),0)]),_c('footer-nav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-header_text"},[_c('p',[_vm._v("关于DPP")]),_c('p',[_vm._v("了解产品数字护照")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-content_title"},[_c('label',[_vm._v("DPP概念提出: 促进数绿双转的重要抓手")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-content_text"},[_c('span',[_vm._v(" 目前，促进碳达峰碳中和已成为全球共识，数字技术赋能成为产业绿色低碳发展的重要路径。为促进数字化绿色化协同发展，2022年3月欧盟在《可持续产品生态设计法规》提案中明确提出了“产品数字护照”(Digital Product Passport,DPP)概念和要求，主要采集核验产品从设计、制造、物流、使用、回收所关联的各类绿色可持续信息。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-content_title"},[_c('label',[_vm._v("欧盟《可持续产品生态设计法规》DPP性质概述")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-content_text"},[_c('span',[_vm._v(" 产品数字护照的本质是一组特定于产品的、可通过数据载体进行电子访问的数据集，通过数据载体链接唯一标识符，在跨国贸易和流通中证明产品的产地、身份及可持续水平。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aboutdpp-title"},[_c('label',[_vm._v("产品数字护照的意义有哪些")]),_c('p',[_vm._v("推动数字化转型, 促进可持续循环经济")])])
}]

export { render, staticRenderFns }
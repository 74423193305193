<template>
    <div class="home-page">
        <div class="home-header" :style="`background-image: url(${headerImg})`">
            <div class="home-header_text">
                <p>欢迎来到</p>
                <p>中国产品数字护照网</p>
                <p>专注中国产品数字护照的专业门户，中国产品数字护照体系服务的客户端统一入口。</p>
            </div>
        </div>
        <!-- 产品数字护照 -->
        <div class="home-passport">
            <div class="home-title">
                <img :src="decorationIcon" />
                <label>产品数字护照</label>
            </div>
            <div class="home-list">
                <div class="home-list_title">什么是产品护照?</div>
                <div class="home-list_item" v-for="(item, index) in passportList" :key="index">
                    <div class="list_item-icon">
                        <img :src="checkIcon" />
                    </div>
                    <div class="list_item-text">{{ item.label }}</div>
                </div>
            </div>
        </div>
        <!-- 中国产品数字护照网 -->
        <div class="home-network">
            <div class="home-title">
                <img :src="decorationIcon" />
                <label>中国产品数字护照网</label>
            </div>
            <div class="home-tabs">
                <a :href="`#${item.anchor}`" :class="{ 'home-tabs_item': true, 'home-tab_active': item.activeTab }" v-for="(item, index) in netWorkList" :key="index" @click="activeTab(index)" @click.prevent="scrollToSection(item.anchor)">
                    <div class="tabs_item-title">{{ item.title }}</div>
                </a>
            </div>
            <div class="home-pannels" id="currentScrollElement" @scroll="mouseScroll" @touchstart="() => isAutoScroll = true">
                <div class="home-pannels_item card-style" :id="item.anchor" v-for="(item, index) in netWorkList" :key="index">
                    <div class="card-style_img">
                        <img :src="item.cardImg" />
                    </div>
                    <div class="card-style_list">
                         <div class="card-style_position" :style="`background-image: url(${cardBottomIcon})`">
                            <div class="style_list-item" v-for="(text, textIndex) in item.list" :key="textIndex">
                                <span v-if="index == 0"></span>
                                <img v-else :src="item.tagType === 'dot' ? dotIcon : checkYIcon" />
                                <label>{{ text.label }}</label>
                            </div>
                         </div>
                    </div>
                    <!-- {{ item.title }} -->
                </div>
            </div>
        </div>
        <!-- 最新工作动态 -->
        <div class="home-dynamics" style="padding: 20px 0 0">
            <div class="home-title">
                <img :src="decorationIcon" />
                <label>最新工作动态</label>
            </div>
            <div class="home-pannels" style="padding: 0">
                <div class="home-pannels_item card-style" v-for="(item, index) in dyamicsList" :key="index">
                    <div class="card-style-time">
                        <span>{{ item.time }}</span>
                    </div>
                    <div class="card-style_img">
                        <img :src="item.cardImg" />
                    </div>
                    <div class="card-style_text">
                        {{ item.title }}
                    </div>
                </div>
            </div>
        </div>
        <footer-nav />
    </div>
</template>
<script>
import decorationIcon from '@/assets/images/home/mobile/decorationIcon.png';
import dotIcon from '@/assets/images/home/mobile/dotIcon.png';
import cardBottomIcon from '@/assets/images/home/mobile/cardBottomIcon.png';
import checkIcon from '@/assets/images/home/mobile/checkIcon.png';
import checkYIcon from '@/assets/images/home/mobile/checkYIcon.png';
import headerImg from '@/assets/images/home/mobile/headerImg.png';
import networkOneImg from '@/assets/images/home/mobile/networkOneImg.png';
import networkTwoImg from '@/assets/images/home/mobile/networkTwoImg.png';
import networkThreeImg from '@/assets/images/home/mobile/networkThreeImg.png';
import dyamicOneImg from '@/assets/images/home/mobile/dyamicOneImg.png';
import dyamicTwoImg from '@/assets/images/home/mobile/dyamicTwoImg.png';
import dyamicThreeImg from '@/assets/images/home/mobile/dyamicThreeImg.png';
export default {
    name: 'mobileHome',
    data() {
        return {
            isAutoScroll: false,
            decorationIcon,
            dotIcon,
            cardBottomIcon,
            checkIcon,
            checkYIcon,
            headerImg,
            passportList: [
                {
                   label: '产品数字护照是人类历史上首次构建的数据共享机制。' 
                },
                {
                    label: '具有全球性、跨行业、多主体,并且涵盖ESG数据等属性。'
                },
                {
                    label: '为碳边境调节税机制调整奠定基础，为再全球化区域经济、贸易的纵深发展提供支撑。'
                },
                {
                    label: '是数字化转型和绿色可持续发展转型的标志。'
                },
                {
                    label: '是碳管理及企业“数绿双转”的重要技术手段。'
                }
            ],
            netWorkList: [
                {
                    anchor: 'anrchor1',
                    title: '宗旨与愿景',
                    cardImg: networkOneImg,
                    tagType: 'dot',
                    list: [
                        { label: '为更好的资源循环' },
                        { label: '为更低的能源碳耗' },
                        { label: '为促进循环经济' },
                        { label: '实现绿色可持续发展' }
                    ]
                },
                {
                    anchor: 'anrchor2',
                    title: '目标与任务',
                    cardImg: networkTwoImg,
                    tagType: 'dot',
                    list: [
                        { label: '以行业项目为切入点' },
                        { label: '定义中国产品数字护照体系解决方案' },
                        { label: '塑造中国产品数字护照的未来' }
                    ]
                },
                {
                    anchor: 'anrchor3',
                    title: '工作与项目',
                    cardImg: networkThreeImg,
                    tagType: 'check',
                    list: [
                        { label: 'DPP相关动态与政策标准研究' },
                        { label: '进行产品数字护照的行业试点与部署' },
                        { label: '产品数字护照企业联合试点验证服务'}
                    ]
                }
            ],
            dyamicsList: [
                {
                    title: '复创大事记 | 人民网 : 一块锂电池的逐“绿”之旅',
                    time: '2023年12月06日',
                    cardImg: dyamicOneImg,
                },
                {
                    title: '南京复创入选Pegasus投融研究所《产品数字护照行业研究报告》',
                    time: '2023年12月06日',
                    cardImg: dyamicTwoImg,
                },
                {
                     title: '南京复创参与中国电池工业协会电池护照工作推进会并做主题汇报',
                     time: '2023年12月06日',
                     cardImg: dyamicThreeImg,
                }
            ]
        }
    },
    mounted() {
        this.activeTab(0);
    },
    methods: {
        activeTab(currentIndex) {
            this.netWorkList = this.netWorkList.map((item, index) => {
                if(currentIndex == index) {
                    item.activeTab = true;
                } else {
                    item.activeTab = false;
                }
                return item;
            })
        },
        scrollToSection(anchorId) {
            this.isAutoScroll = false;
            const element = document.getElementById(anchorId);
            if (element) {
                element.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center' // 确保水平滚动到起始位置
                });
            }
        },
        mouseScroll(event) {
            if(!this.isAutoScroll) return;
            const scrollLeftOClient = event.target.scrollLeft + event.target.clientWidth;
            const docWidth = event.target.querySelector('.home-pannels_item').clientWidth;
            if(scrollLeftOClient < docWidth * 2 + 24 + 10) {
                this.activeTab(0);
                return;
            }
            if(scrollLeftOClient < docWidth * 3 + 24 + 20) {
                this.activeTab(1);
                return;
            }
            this.activeTab(2);
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
<template>
    <div class="page flex-col">
      <div class="block_work flex-col">
        <div class="text-wrapper_1 flex-col">
          <span class="text_1">工作动态</span>
          <span class="text_2">Work&nbsp;dynamics</span>
        </div>
        <!-- 新闻列表 -->
        <div v-for="(item, index) in newsList" :key="index">
          <div class="group_news flex-row" v-if="item.cardUrl">
            <img class="image_card" referrerpolicy="no-referrer" :src="item.cardImg" />
            <div class="card_body flex-col">
              <span class="body_card_title">{{ item.cardTitle }}</span>
              <div class="body_card_date">
                <div class="card_date_body flex-row justify-between">
                  <div class="date_body_wrapper flex-col">
                    <img class="date_body_image" referrerpolicy="no-referrer" :src="item.cardIcon" />
                  </div>
                  <span class="date_body_text">{{ item.cardDate }}</span>
                </div>
              </div>
              <span class="body_card_content" v-if="item.cardContent">{{ item.cardContent }}</span>
              <div class="body_card_btns flex-col">
                <span type="primary" class="card_btns_text" @click="convertUrl(item.cardUrl)">查看详情</span>
              </div>
            </div>
          </div>
        </div>
        <footer-nav />
      </div>
    </div>
  </template>
  <script>
  import timeIcon from '@/assets/images/policyComment/time-icon.png';
  import workNewsCover1 from '@/assets/images/workNews/workNewsCover1.png';
  import workNewsCover2 from '@/assets/images/workNews/workNewsCover2.png';
  import workNewsCover3 from '@/assets/images/workNews/workNewsCover3.png';
  import workNewsCover4 from '@/assets/images/workNews/workNewsCover4.png';
  import workNewsCover5 from '@/assets/images/workNews/workNewsCover5.png';
  export default {
    name: 'webWorkNews',
    data() {
      return {
        newsList: [
          {
            cardImg: workNewsCover1,
            cardTitle: '首张产品数字护照诞生！中国制造迎来数字化转型新突破',
            cardIcon: timeIcon,
            cardDate: '2024年12月27日 09:00',
            cardContent: '12月23日，在2025中国信通院深度观察报告会主论坛上，中国信息通信研究院（以下简称“中国信通院”）党委副书记王晓丽、南京玄武高新技术产业集团有限公司副总经理王涛、蜂巢能源科技股份有限公司高级副总裁张放南共同发布首张产品数字护照。这标志着我国产品数字护照体系建设已迈出重要一步，是工业互联网支撑产业链供应链保障、促进绿色低碳发展的重要展现，将为我国加快制造业数字化转型、推进新型工业化提供有力支撑。',
            cardBtn: '查看详情',
            cardUrl: 'https://mp.weixin.qq.com/s/2ifKKTad5dex2-0l8NvQ-g'
          },
          {
            cardImg: workNewsCover2,
            cardTitle: '六家中国电池企业公开电池护照，消费者可直接查阅动力电池溯源信息',
            cardIcon: timeIcon,
            cardDate: '2024年12月12日 09:00',
            cardContent: '近日，全球电池联盟发布了2024年电池护照试点项目结果。10张电池护照试点项目中，中国电池企业参与的数量就有8张。',
            cardBtn: '查看详情',
            cardUrl: 'https://mp.weixin.qq.com/s/QA74E4_basSoVTsHdBkMFA'
          },
          {
            cardImg: workNewsCover3,
            cardTitle: '新闻快讯丨中国信通院&南京复创联合牵头，设立中国电池工业协会数智化专业委员会议案获通过！',
            cardIcon: timeIcon,
            cardDate: '2024年12月05日 14:40',
            cardContent: '12月5日，在天津召开的中国电池工业协会八届九次常务理事扩大会上，中国电池工业协会副秘书长张蓉提请常务理事会审议设立中国电池工业协会数智化专业委员会的议案，经理事会成员现场表决，获得全票通过！',
            cardBtn: '查看详情',
            cardUrl: 'https://mp.weixin.qq.com/s/SpTYtaNZDTQdzZSQo2Tyaw'
          },
          {
            cardImg: workNewsCover4,
            cardTitle: '欧盟正式发布数字产品护照法案!',
            cardIcon: timeIcon,
            cardDate: '2024年11月15日 09:00',
            cardContent: '据艾科森环境技术欧洲站消息，11月12日，欧盟委员会正式通过一项授权法案，制定数字产品护照（DPP )服务提供商的运营规则。这项授权法案也是为欧盟新电池法配套二级立法电池护照提供相关的技术指导。目前，该授权方案处于公众咨询期，从2024年11月12日到12月10日，预计第四季度会出台相关草案，并于明年第四季度发布最终版。',
            cardBtn: '查看详情',
            cardUrl: 'https://mp.weixin.qq.com/s/Hwn1EBSzZs3wTJVCJQOhZg'
          },
          {
            cardImg: workNewsCover5,
            cardTitle: '全球电池联盟发布第二阶段电池护照试点，南京复创被提名',
            cardIcon: timeIcon,
            cardDate: '2024年11月12日 11:06',
            cardContent: '全球电池联盟 (GBA) 于2024年11月7日在中国上海举行的年度大会上，公布了2024年电池护照试点项目的最终成果。这次试点由全球领先的电池制造商组建的十余个团队，完成了为建立统一电池护照的最大规模非竞争性合作。其中南京复创与亿纬、宝马联手参与的试点项目，成功跻身本次“电池护照”十大试点项目之列，这标志着南京复创作为领先的数字化解决方案提供商，在更透明和可持续的电池价值链上的探索上获得全球电池联盟的认可。',
            cardBtn: '查看详情',
            cardUrl: 'https://mp.weixin.qq.com/s/iFpx4GzdW4e69pQ7Epw3lg'
          },
        ],
      };
    },
    methods: {
      convertUrl(url) {
        window.open(url, '_blank')
      }
    },
  };
  </script>
  <style lang="less" scoped>
  @import url('./index.less');
  </style>
  
<template>
    <div class="page flex-col">
        <div class="block_2 flex-col">
            <div class="group_10 flex-col">
                <div class="text-wrapper_1 flex-col">
                    <span class="text_9">开放连接，共创未来！</span>
                    <span class="text_10">浏览最新的CBIA技术文档，含试点指南、平台操作手册、接口文档、护照模型。助您轻松使用CBIA平台。</span>
                </div>
            </div>
            <div class="group_1 flex-row">
                <div class="image-text_1 flex-row justify-between">
                    <img class="label_1" referrerpolicy="no-referrer" :src="decorationIcon" />
                    <span class="text-group_1">文档列表</span>
                </div>
            </div>
            <div class="group_2 flex-row justify-between">
                <div class="group_3 flex-col" v-for="(item, index) in cards" :key="index">
                    <div class="image-wrapper_1 flex-col">
                        <img class="image_1" referrerpolicy="no-referrer" :src="item.icon" />
                    </div>
                    <div class="text-group_2 flex-col justify-between">
                        <span class="text_1">{{ item.title }}</span>
                        <span class="text_2">{{ item.label }}</span>
                    </div>
                    <Popover placement="bottom-end" trigger="click" popper-class="popover-content">
                        <div class="group_11 flex-col" v-if="item.downloads && item.downloads.length">
                            <div class="text-group_10 flex-col justify-between">
                                <span class="text_11" v-for="(element, eleIndex) in item.downloads" :key="eleIndex">
                                    <a :href="item.value" download>{{ element.label }}</a>
                                </span>
                            </div>
                        </div>
                        <div class="section_1 flex-col" slot="reference">
                            <div class="block_3 flex-row">
                                <div class="image-text_2 flex-row justify-between" v-if="!item.downloads">
                                    <a class="text_url" :href="item.downloadUrl" download>
                                        <span class="text-group_3">下载文档</span>
                                        <img class="thumbnail_1" referrerpolicy="no-referrer" :src="downloadIcon" />
                                    </a>
                                </div>
                                <div class="image-text_2 flex-row justify-between" v-else>
                                    <span class="text-group_3">下载文档</span>
                                    <img class="thumbnail_1" referrerpolicy="no-referrer" :src="downloadIcon" />
                                </div>
                            </div>
                        </div>
                    </Popover>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import decorationIcon from '@/assets/images/home/decoration.png';
import sdzlIcon from '@/assets/images/download/sdzl_icon.png';
import ptczscIcon from '@/assets/images/download/ptczsc_icon.png';
import jkwdIcon from '@/assets/images/download/jkwd_icon.png';
import hzmxIcon from '@/assets/images/download/hzmx_icon.png';
import downloadIcon from '@/assets/images/download/download_icon.png';
import { Popover, Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
export default {
    name: 'webDownload',
    components: {
        Popover,
        Dropdown,
        DropdownMenu,
        DropdownItem
    },
    data() {
        return {
            decorationIcon,
            sdzlIcon,
            ptczscIcon,
            jkwdIcon,
            hzmxIcon,
            downloadIcon,
            cards: [
                {
                    title: '试点指南',
                    label: 'CBIA试点指南旨在帮助企业了解CBIA平台基本情况，以及试点背景、试点目标和企业的准备工作、评价标准等信息。',
                    icon: sdzlIcon,
                    downloads: [
                        { label: '202401版本', value: 'javascript:void(0)' },
                        { label: '202404版本', value: 'javascript:void(0)' },
                        { label: '202405版本', value: 'javascript:void(0)' }
                    ]
                },
                {
                    title: '平台操作手册',
                    label: '该文档对平台所有功能进行简要介绍。包括环境、主要功能、操作流程等，帮助您快速了解平台的功能情况。',
                    icon: ptczscIcon,
                    downloadUrl: 'javascript:void(0)'
                },
                {
                    title: '接口文档',
                    label: '提供专业的接口文档，帮助您快速完成平台的功能对接。实现业务流程自动化，从而提升工作效率。',
                    icon: jkwdIcon,
                    downloadUrl: 'javascript:void(0)'
                },
                {
                    title: '护照模型',
                    label: '提供EV电池和LMT电池的护照数据模型。文档包含使用说明、数据属性、数据类型、数据示例、数据披露权限等关键信息。',
                    icon: hzmxIcon,
                    downloadUrl: 'javascript:void(0)'
                }
            ]
        };
    },
    methods: {},
};
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
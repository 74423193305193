var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page flex-col"},[_c('div',{staticClass:"group_faq flex-col"},[_vm._m(0),_c('div',{staticClass:"faq-title flex-row justify-between"},[_c('img',{staticClass:"title-icon",attrs:{"referrerpolicy":"no-referrer","src":_vm.decorationIcon}}),_c('span',{staticClass:"title-label"},[_vm._v("常见问题")])]),_c('img',{staticClass:"faq-image",attrs:{"referrerpolicy":"no-referrer","src":_vm.cardBackgroundImg}}),_vm._l((_vm.issueList),function(item,index){return _c('div',{key:index,staticClass:"faq-block flex-col"},[_c('div',{staticClass:"block-wrapper_1 flex-row",on:{"click":function($event){return _vm.collapse(index, item)}}},[_c('span',{class:[
            'block_text1_1',
            item.serialColor ? 'block_text1_color1' : null,
          ]},[_vm._v(_vm._s(item.serialNo))]),_c('span',{class:[
            'block_text1_2',
            item.labelColor ? 'block_text1_color2' : null,
          ]},[_vm._v(_vm._s(item.label))])]),(item.collapsed)?_c('div',{staticClass:"block-wrapper_2 flex-col"},[_c('p',[_c('span',{staticClass:"block_text2_1"},[_vm._v("答:  ")]),_c('span',{staticClass:"block_text2_2"},[_vm._v(_vm._s(item.expendLabel))])])]):_vm._e()])})],2),_c('footer-nav')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"faq_header flex-col"},[_c('div',{staticClass:"header_body flex-col"},[_c('div',{staticClass:"body_wrapper"},[_c('span',{staticClass:"body_text1"},[_vm._v("关于DPP的问题？请参阅我们的")]),_c('span',{staticClass:"body_text2"},[_vm._v("中国电池护照技术发展报告")])])])])
}]

export { render, staticRenderFns }
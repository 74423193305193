<template>
    <div class="download-page">
       <div 
         class="download-header"
         :style="`background-image: url(${groupBackgroundImg})`"
         >
           <div class="download-header_text">
             <p>开放连接, 共创未来!</p>
             <p>浏览最新的cbia技术文档，含试点指南、平台操作手册、接口文档、护照模型。助您轻松使用cbia平台。</p>
           </div>
       </div>
       <!-- 文档列表 -->
       <div 
         class="download-list"
         :style="`background-image: url(${wrapperBackgroundImg})`"
        >
          <div class="download-title">
             <img :src="decorationIcon" />
             <label>文档列表</label>
          </div>
          <div class="download-list_cards">
             <div class="list_cards-item" v-for="(item, index) in cardsList" :key="index">
                <div class="cards-item_icon">
                    <img :src="item.icon" />
                </div>
                <div class="cards-item_title"><label>{{ item.title }}</label></div>
                <div class="cards-item_desc"><span>{{ item.desc }}</span></div>
                <div class="cards-item_download">
                    <span>下载文档</span>
                    <img :src="item.downloadIcon" />
                </div>
             </div>
          </div>
       </div>
    </div>
</template>
<script>
import decorationIcon from "@/assets/images/download/mobile/decorationIcon.png";
import groupBackgroundImg from "@/assets/images/download/mobile/groupBackgroundImg.png";
import wrapperBackgroundImg from "@/assets/images/download/mobile/wrapperBackgroundImg.png";
import cardOneIcon from "@/assets/images/download/mobile/frameOneIcon.png";
import cardTwoIcon from "@/assets/images/download/mobile/frameTwoIcon.png";
import cardThreeIcon from "@/assets/images/download/mobile/frameThreeIcon.png";
import cardFourIcon from "@/assets/images/download/mobile/frameFourIcon.png";
import downloadIcon from "@/assets/images/download/mobile/downloadIcon.png";
export default {
    name: 'mobileDownload',
    data() {
        return {
            decorationIcon,
            groupBackgroundImg,
            wrapperBackgroundImg,
            cardOneIcon,
            cardTwoIcon,
            cardThreeIcon,
            cardFourIcon,
            cardsList: [
                {
                    title: '试点指南',
                    desc: 'CBIA试点指南旨在帮助企业了解CBIA平台基本情况，以及试点背景、试点目标和企业的准备工作、评价标准等信息。',
                    icon: cardOneIcon,
                    downloadIcon,
                },
                {
                    title: '平台操作手册',
                    desc: '该文档对平台所有功能进行简要介绍。包括环境、主要功能、操作流程等，帮助您快速了解平台的功能情况。',
                    icon: cardTwoIcon,
                    downloadIcon,
                },
                {
                    title: '接口文档',
                    desc: '提供专业的接口文档，帮助您快速完成平台的功能对接。实现业务流程自动化，从而提升工作效率。',
                    icon: cardThreeIcon,
                    downloadIcon,
                },
                {
                    title: '护照模型',
                    desc: '提供EV电池和LMT电池的护照数据模型。文档包含使用说明、数据属性、数据类型、数据示例、数据披露权限等关键信息。',
                    icon: cardFourIcon,
                    downloadIcon,
                },
            ]
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
